import request from '@/utils/request'
import qs from "qs"
export function getCase(params) {
  return request({
    url: 'api/front/classicCase',
    method: 'get',
    params
  })
}

export function test() {
  return request({
    url: 'api/front/registe/test',
    method: 'get'
  })
}

export function login(data) {
  return request({
    url: 'api/front/login',
    method: 'post',
    data
  })
}

export function del(ids) {
  return request({
    url: 'api/front/registe/',
    method: 'delete',
    data: ids
  })
}

export function edit(data) {
  return request({
    url: 'api/front/registe/',
    method: 'put',
    data
  })
}

export default { getCase }
